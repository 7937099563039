import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PrayerCard from "../components/prayer"

const Hello = () => (
  <Layout>
    <div style={{ padding: "16px 21px", marginTop: "24px" }}>
      <SEO title="Prayer" />
      <p>
        We have a team of community members that will receive your specific
        request for prayer and have committed to praying for you. If you have a
        prayer answered or just a reason to celebrate a season of life we’d love
        to hear about that too!
      </p>
      <h1 class="center" style={{ marginBottom: "48px" }}>
        Prayer
      </h1>
      <PrayerCard />
    </div>
  </Layout>
)

export default Hello
