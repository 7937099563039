import React from "react"
import "./forms.css"

const PrayerCard = () => (
  <div id="form-wrapper">
    <form
      name="Prayer Card"
      method="POST"
      data-netlify="true"
      action="/success"
    >
      <input type="hidden" name="form-name" value="Prayer Card" />
      <p>
        <label>
          <input type="text" name="name" placeholder="Name" />
        </label>
      </p>
      <p>
        <label>
          <input type="email" name="email" placeholder="Email" />
        </label>
      </p>
      <p>
        <label>
          <input type="phone" name="phone" placeholder="Phone" />
        </label>
      </p>
      <p>
        <label>
          <textarea
            type="textarea"
            name="prayer"
            rows="7"
            placeholder="Your praise or prayer goes here."
          />
        </label>
      </p>
      <p class="button-wrapper">
        <button type="submit">Send</button>
      </p>
    </form>
  </div>
)

export default PrayerCard
